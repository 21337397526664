@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @include respond-to(768px) {
    padding-top: 50px;
  }

  @include respond-to(720px) {
    padding-top: 90px;
  }
}

.isTablet {
  padding-top: 300px;

  @include respond-to(1180px) {
    padding-top: 300px;
  }

  @include respond-to(980px) {
    padding-top: 0;
  }

  @include respond-to(720px) {
    padding-top: 80px;
  }
}

.intro_video {
  position: relative;
  height: 656px;
  width: 1228px;
  overflow: hidden;

  @include respond-to(1440px) {
    width: 90vw;
    height: 666px;
  }
}

.logo {
  margin-right: 20px;
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo_text {
  @include lightText(56px, 79px);
  text-transform: uppercase;
  border-bottom: 1px solid #212224;
  padding-bottom: 5px;

  @include respond-to(768px) {
    @include lightText(24px, 29px);
  }
}

.logo_image {
  margin-left: 20px;
  margin-right: 20px;
}

.upgrate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  @include respond-to(512px) {
    flex-direction: column;
  }
}

.logo_image {
  @include respond-to(768px) {
    width: 32px;
    height: 32px;
  }
}

.upgrate_text {
  background-color: $default-background-color;
  border-radius: 50px;
  padding: 11px 45px;
  @include mediumText(40px, 58px);
  text-transform: uppercase;
  margin-right: 18px;

  @include respond-to(768px) {
    @include mediumText(16px, 19px);
    padding: 10px 55px;
    margin-right: 0;
    background-color: transparent;
  }

  @include respond-to(512px) {
    padding: 10px 30px;
  }
}

.bigPhone {
  @include videoPlayer(45%, 95%);
  margin-top: 50px;

  @include respond-to(1440px) {
    @include videoPlayer(45%, 1050px, 800px);
  }

  @include respond-to(980px) {
    @include videoPlayer(45%, 950px, 800px);
  }
}

.wrapper_iphone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iphone {
  margin-top: 33px;
  margin-bottom: 38px;
}

.upgrate_button {
  max-width: fit-content;
  text-align: center;
  @include defaultStyleButton();
  font-size: 14px;

  @include respond-to(512px) {
    @include defaultStyleButton(12px, 10px, 12px, 10px);
    @include mediumText(14px, 17px);
    background-color: #ffc700;
    color: #212224;
    margin-top: 6px;
    display: block;
    min-width: calc(100% - 30px);
  }
}
